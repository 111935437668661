import React from 'react';
import { Tag, Tooltip, Icon } from 'antd';
import { useTranslation } from 'react-i18next';

const ExceededDurationAlert = ({
  programmatic,
  slotLength,
  contentLength,
  pricePerDay
}) => {

  const { t } = useTranslation();
  const exceededSeconds = Math.floor((contentLength - slotLength) / 1000);

  const calculateExtraPrice = () => {
    return Math.round((pricePerDay * (Math.floor(contentLength / 1000) * 1000) / slotLength - pricePerDay) * 100) / 100;
  };

  // If not exceeded duration, not render
  if ( exceededSeconds < 1 ) {
    return null;
  }

  return (
    <>
      {programmatic === 'programmatic' ? (
        <Tooltip
          placement="left"
          title={
            <span>{t('In programmatic the ads should not exceed the maximum time of the advertising space on the screen, otherwise they could be cut off in this time')}</span>
          }
        >
          <Tag color="#f74f48" style={{marginTop: "5px" }}>
            <span>
              {t('Exceeded in')} {exceededSeconds} {t('secs')} <Icon type="question-circle"/>
            </span>
          </Tag>
        </Tooltip>
      ) : programmatic === 'traditional' ? (
        <Tooltip
          placement="left"
          title={
            <span>
              {t('If you do not change the ad for a shorter one, it will add approximately')} ${calculateExtraPrice()} {t('per day for this screen.')}
            </span>
          }
        >
          <Tag color="#f74f48" style={{marginTop: "5px" }}>
            <span>
              {t('Exceeded in')} {exceededSeconds} {t('secs')} <Icon type="question-circle"/>
            </span>
          </Tag>
        </Tooltip>
      ) : null}
      <br />
    </>
  );
};

export default ExceededDurationAlert;