import { connect } from 'react-redux'
import ScreensCollectionComponent from "../components/ScreensCollectionComponent"
import DisplayActions from '../redux/DisplayRedux'

const mapStateToProps = ({ display }) => ({
  cart: display.cart,
  managerDisplayFilter: display.managerDisplayFilter,
  managerDisplaySelector: display.managerDisplaySelector,
  disabledManagerDisplayFilter: display.disabledManagerDisplayFilter,
  programmatic: display.programmatic,
  customContents: display.customContents,
  currency: display.currency,
  audience: display.audience,
  editCampaignActive: display.editCampaignActive,
  currentCampaign: display.currentCampaign,
})

const mapStateToDispatch = (dispatch) => ({
  disableManagerDisplayFilter: (disabledManagerDisplayFilter) => dispatch(DisplayActions.disableManagerDisplayFilter(disabledManagerDisplayFilter)),
})

export default connect(mapStateToProps, mapStateToDispatch)(ScreensCollectionComponent)