import React, { useMemo } from 'react';
import { Tooltip, Icon } from 'antd';
import { useTranslation } from 'react-i18next';

const ExceededGroupDurationAlert = ({
  displays,
  customContentsFiltered,
  styleCustom,
  listContents
}) => {
  const { t } = useTranslation();

  const isExceeded = useMemo(() => {
    if (displays.length === 0) {
      return false;
    }
    return displays.some(display => {
      if(customContentsFiltered && !listContents) {
        const customContentAux = customContentsFiltered.filter(customContent => customContent.displayId === display.id);
        return customContentAux.some(customContent => Math.floor(customContent.content.length / 1000) * 1000 > display.slot_length);
      }
      if(!customContentsFiltered && listContents) {
        return listContents.some(content => Math.floor(content.length / 1000) * 1000 > display.slot_length);
      }
    });
  }, [listContents, customContentsFiltered]);

  const getTooltipMessage = () => {
    if (displays.length === 1) {
      return t('Some content does not meet the specifications on this screen');
    }
    return t('Some content does not meet the specifications on one or more screens in this group');
  };

  return (
    isExceeded && (
      <>
        <Tooltip
          placement="left"
          title={getTooltipMessage()}
        >
          <Icon
            style={styleCustom ? styleCustom : {fontSize: '20px', marginLeft: '10px', color: '#f44336', verticalAlign: 'bottom'}}
            type="warning"
          />
        </Tooltip>
        <br />
      </>
    )
  );
};

export default ExceededGroupDurationAlert;